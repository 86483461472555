import React from "react";
import Layout from "../components/layout";
import Header from "../components/header";
import SEO from "../components/seo";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import bgHeader from "../images/bg-header.jpg";
import ImageGrid from "../components/imageGrid";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import arrowForward from "../images/arrow-forward.svg";
import { altMapImages } from "../utils";
import metaImage from "../images/meta_share_index.jpg";

export default function Home({pageContext}) {

  const getFeaturedImagesQuery = graphql`
    query GetFeaturedImages {
      coasters: allFile(
        limit: 12,
        sort: {order: DESC, fields: name},
        filter: {
          relativeDirectory: { regex: "/projects/watermarked/coasters/"}
        }
      ) {
        edges {
          node {
            relativeDirectory
            name
            childImageSharp {
              ...imageSetupFragment
            }
          }
        }
      },

      tables: allFile(
        limit: 12,
        sort: {order: DESC, fields: name},
        filter: {
          relativeDirectory: { regex: "/projects/watermarked/tables/"}
        }
      ) {
        edges {
          node {
            relativeDirectory
            name
            childImageSharp {
              ...imageSetupFragment
            }
          }
        }
      },

      signs: allFile(
        limit: 12,
        sort: {order: DESC, fields: name},
        filter: {
          relativeDirectory: { regex: "/projects/watermarked/signs/"}
        }
      ) {
        edges {
          node {
            relativeDirectory
            name
            childImageSharp {
              ...imageSetupFragment
            }
          }
        }
      },

      wallArt: allFile(
        limit: 12,
        sort: {order: DESC, fields: name},
        filter: {
          relativeDirectory: { regex: "/projects/watermarked/wall-art/"}
        }
      ) {
        edges {
          node {
            relativeDirectory
            name
            childImageSharp {
              ...imageSetupFragment
            }
          }
        }
      },

      other: allFile(
        limit: 12,
        sort: {order: DESC, fields: name},
        filter: {
          relativeDirectory: { regex: "/projects/watermarked/other/"}
        }
      ) {
        edges {
          node {
            relativeDirectory
            name
            childImageSharp {
              ...imageSetupFragment
            }
          }
        }
      }
    }
  `

  const coasterImages = altMapImages(
    useStaticQuery(getFeaturedImagesQuery).coasters.edges,
    pageContext.altDescriptions.coasters
  );

  const tableImages = altMapImages(
    useStaticQuery(getFeaturedImagesQuery).tables.edges,
    pageContext.altDescriptions.tables
  );

  const signImages = altMapImages(
    useStaticQuery(getFeaturedImagesQuery).signs.edges,
    pageContext.altDescriptions.signs
  );

  const wallArtImages = altMapImages(
    useStaticQuery(getFeaturedImagesQuery).wallArt.edges,
    pageContext.altDescriptions.wallArt
  );

  const otherImages = altMapImages(
    useStaticQuery(getFeaturedImagesQuery).other.edges,
    pageContext.altDescriptions.other
  );

  return (
    <Layout includeHeader={false}>
      <SEO
        image={metaImage}
        imageAlt="mixed colours of blue, white, yellow, purple with green specks"
      />
      <div className="bg-cover bg-center mb-1" style={{ backgroundImage: `url(${bgHeader})` }}>
        <Header includeLogo={false}/>
        <div className="max-w-screen-xl mx-auto px-4-safe-area sm:px-6-safe-area">
          <h1 className="text-4xl pt-1 pb-2 sm:py-5 sm:text-6xl md:pt-8 md:text-7xl lg:pt-12 animate-fade-in-left">ColourStormArt</h1>
          <p className="text-xl font-old-standard pb-8 sm:text-3xl sm:pb-14 md:text-4xl animate-fade-in-left">Unique designs inspired by <br className="md:hidden"/>nature's formations</p>
        </div>
      </div>

      <main className="w-full flex-grow max-w-screen-xl mx-auto py-4 px-4-safe-area sm:px-6-safe-area" role="main">
        <section>
          <h2 className="text-3xl pt-1 pb-2 md:text-4xl md:pb-4 md:pt-5 lg:pt-6">Latest Projects</h2>

          <Tabs selectedTabClassName="border-white">
            <TabList className="flex flex-row flex-wrap -mx-2 pb-4 md:text-lg">
              <Tab className="cursor-pointer focus:outline-none border-b-2 border-black m-2 md:mx-3">Coasters</Tab>
              <Tab className="cursor-pointer focus:outline-none border-b-2 border-black m-2 md:mx-3">Tables</Tab>
              <Tab className="cursor-pointer focus:outline-none border-b-2 border-black m-2 md:mx-3">Signs</Tab>
              <Tab className="cursor-pointer focus:outline-none border-b-2 border-black m-2 md:mx-3">Wall Art</Tab>
              <Tab className="cursor-pointer focus:outline-none border-b-2 border-black m-2 md:mx-3">Other</Tab>
            </TabList>

            <TabPanel>
              <ImageGrid images={coasterImages} />
              <Link className="inline-block underline mt-5 whitespace-nowrap" to="/projects/coasters/">
                View all Coasters<img className="inline ml-1" width="20" height="20" alt="Arrow forward" src={arrowForward} />
              </Link>
            </TabPanel>
            <TabPanel>
              <ImageGrid images={tableImages} />
              <Link className="inline-block underline mt-5 whitespace-nowrap" to="/projects/tables/">
                View all Tables<img className="inline ml-1" width="20" height="20" alt="Arrow forward" src={arrowForward} />
              </Link>
            </TabPanel>
            <TabPanel>
            <ImageGrid images={signImages} />
              <Link className="inline-block underline mt-5 whitespace-nowrap" to="/projects/signs/">
                View all Signs<img className="inline ml-1" width="20" height="20" alt="Arrow forward" src={arrowForward} />
              </Link>
            </TabPanel>
            <TabPanel>
            <ImageGrid images={wallArtImages} />
              <Link className="inline-block underline mt-5 whitespace-nowrap" to="/projects/wall-art/">
                View all Wall Art<img className="inline ml-1" width="20" height="20" alt="Arrow forward" src={arrowForward} />
              </Link>
            </TabPanel>
            <TabPanel>
            <ImageGrid images={otherImages} />
              <Link className="inline-block underline mt-5 whitespace-nowrap" to="/projects/other/">
                View all other works<img className="inline ml-1" width="20" height="20" alt="Arrow forward" src={arrowForward} />
              </Link>
            </TabPanel>
          </Tabs>

          <p className="mt-4">If you'd like to know more, or to request your own commission <Link className="underline" to="/contact/">contact me</Link>.</p>
        </section>
        {/* <section className="mb-6">
          <h2 className="text-3xl pt-1 pb-2 md:text-4xl md:pb-4 md:pt-5 lg:pt-6">Testimonials</h2>
          <p>Recent comments on ColourStormArt work</p>
        </section> */}
      </main>
    </Layout>

  )
}
